import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AdminUser } from '@Core/CodeGen/Models/admin.models';
import { Area, TenantUser, UserNotificationCenter as TenantUserNotificationCenter, Notification as TenantNotification, UserNotificationCenter } from '@Core/CodeGen/Models/configuration.models';
import { TenantContext } from '@Core/Lib/Contexts/tenant-context';
import { NgbActiveModal, NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@Services/auth-service';
import { SyncHubStatusService } from '@Services/sync-hub-status-service';
import { UserService } from '@Services/user-service';
import { SmAssignableBadgeVM } from "@Shared/Components/sm-assignable-badge/sm-assignable-badge.viewmodel";
import _ from 'lodash';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SmSelectAreaModalComponent } from '../sm-select-area/sm-select-area-modal.component';
import { SmSimpleDialog, SimpleDialogOptions } from '../sm-simple-dialog/sm-simple-dialog.component';
import { SyncStatus } from '../sm-sync-status/sm-sync-status.component';
import { AccountsService } from '@Core/CodeGen/Services/accounts.service';
import { TenantService } from '@Core/CodeGen/Services/tenant.service';
import { UserNotificationCenter as AccountUserNotificationCenter, Notification as AccountNotification } from '@Core/CodeGen/Models/area.models';
import { UserContext } from '@Core/Lib/Contexts/user-context';
import { NotificationCardVM, NotificationDomain, NotificationSubtitleVM } from '../sm-global-notifications/sm-global-notifications-card/sm-global-notifications-card.component';
import { TenantAssignableService } from '@Services/tenant-assignable-service';
import { TenantPermissions } from '@Core/CodeGen/tenant-permissions.enum';
import { FeatureFacetService } from '@Services/feature-facet.service';
import { RouteEntryItem, RouteUtils } from '@Core/Lib/Utils/route-utils';
import { NotificationType } from '@Core/CodeGen/Eums/library.enums';
import { SmNomenclaturePipe } from '@Shared/Pipes/sm-nomenclature.pipe';
import { AsyncPipe } from '@angular/common';
import { GuidReplacerPipe } from '@Work/Pipes/guid-replacer.pipe';
import { TenantTenantService } from '@Services/tenant-tenant-service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
    selector: 'unity-header',
    templateUrl: './unity-header.component.html',
    styleUrls: ['./unity-header.component.scss']
})
export class UnityHeaderComponent implements OnDestroy, OnInit {
    @Input() view: string;

    private ngUnsubscribe: Subject<any> = new Subject();
    private subscription: Subscription;

    private hasLoadedAccountNotifications: boolean = false;
    private hasLoadedTenantNotifications: boolean = false;

    public syncStatus: SyncStatus = null;
    public user: TenantUser;
    public adminUser: AdminUser;
    public badge: SmAssignableBadgeVM;
    public areas: Area[] = [];
    public filteredAreas: Area[] = [];
    public areaKey: string;
    public smNomenclaturePipe: SmNomenclaturePipe;
    public asyncPipe: AsyncPipe;
    private guidReplacerPipe: GuidReplacerPipe;
    private hasAccessToConfigure: boolean = false;

    private notifications: (TenantNotification | AccountNotification)[] = [];
    public notificationCenter: UserNotificationCenter;
    public notificationCardVMs: NotificationCardVM[] = [];
    public TENANT_PERMISSIONS = TenantPermissions;

    @ViewChild('notifyPopover') public notifyPopover: NgbPopover;

    constructor(
        private router: Router,
        private userService: UserService,
        private syncStatusService: SyncHubStatusService,
        private authService: AuthService,
        private modalService: NgbModal,
        private tenantContext: TenantContext,
        private tenantService: TenantService,
        private accountService: AccountsService,
        private userContext: UserContext,
        private tenantAssignableService: TenantAssignableService,
        private featureFacetService: FeatureFacetService,
        private cdRef: ChangeDetectorRef, 
        private tenantTenantService: TenantTenantService,
        private permissionsService: NgxPermissionsService
    ) { }

    ngOnInit() {
        this.getUserAndBadge();    
        this.smNomenclaturePipe = new SmNomenclaturePipe(this.tenantContext);
        this.asyncPipe = new AsyncPipe(this.cdRef);
        this.guidReplacerPipe = new GuidReplacerPipe(this.tenantContext, this.tenantAssignableService, this.tenantTenantService);

        this.permissionsService.hasPermission([
            TenantPermissions.SystemAccess,
            TenantPermissions.Tenant,
            TenantPermissions.Programs,
        ]).then((result) => {
            this.hasAccessToConfigure = result;
            this.buildNotificationCardVMs();
        });

        if (this.view != 'Admin') {
            combineLatest([
                this.authService.authContext$,
                this.tenantContext.getStore(new Area()).values,
                this.featureFacetService.getAllFacets()
            ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(([authContext, areas, featureFacets]) => {
                if (!authContext || !areas?.length) {
                    return;
                }

                this.getUserAreas(areas);

                if (this.user)
                    this.getTenantUserNotifications();
            });
        }

        this.subscription = this.syncStatusService.status.subscribe((status: string) => {
            this.syncStatus = <SyncStatus>status;
        });

        combineLatest([
            this.userContext.getStore<TenantUserNotificationCenter>(new TenantUserNotificationCenter()).values,
            this.userContext.getStore<AccountUserNotificationCenter>(new AccountUserNotificationCenter()).values
        ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(([tenantCenters, accountCenters]) => {
            this.notifications = [];

            _.forEach(tenantCenters[0]?.IsAlertedBy() || [], (edge) => this.notifications.push(edge.IsAlertedBy()));
            _.forEach(accountCenters[0]?.IsAlertedBy() || [], (edge) => this.notifications.push(edge.IsAlertedBy()));

            this.notificationCardVMs = this.buildNotificationCardVMs();
            this.notificationCenter = tenantCenters[0];
        });
    }

    private getUserAreas(areas: Area[]): void {
        var areasUserHasAccessTo = this.authService.getAvailableAreasForCurrentTenant();
        // Get the areas and sort based on order
        this.areas = _.sortBy(areas, a => a.Order);
        // Clear filtered Areas
        this.filteredAreas = [];

        _.each(areasUserHasAccessTo, area => {
            var foundArea = _.find(this.areas, a => a.Name == area)
            if (foundArea)
                this.filteredAreas.push(foundArea);
        });

        this.filteredAreas = _.sortBy(this.filteredAreas, a => a.Order);
    }

    private getUserAndBadge(): void {
        if (this.view == 'Admin') {
            this.userService.AdminUser$.subscribe(adminUser => {
                if (!adminUser) return;

                this.adminUser = adminUser;
                this.buildUserBadge();
            });
        } else {
            this.userService.User$.subscribe(tenantUser => {
                if (!tenantUser) return;

                this.user = tenantUser;
                this.buildUserBadge();
            });
        }
    }

    private buildNotificationCardVMs(): NotificationCardVM[] {
        const vms: NotificationCardVM[] = this.notifications.map(notification => {

            var domain = notification instanceof TenantNotification ? NotificationDomain.Tenant : NotificationDomain.Account;

            var programId = notification?.ContextIds ? notification?.ContextIds["Program"] : null;

            var lastSubtitleNum = _.max(_.map(notification.Subtitles, s => s.OrderNum));
            const subtitles: NotificationSubtitleVM[] = notification.Subtitles.map((subtitle: NotificationSubtitle) => {
                var noConfigLink = domain == NotificationDomain.Tenant && !this.hasAccessToConfigure;

                return {
                    EntityType: subtitle.EntityType,
                    DisplayEntityType: this.asyncPipe.transform(this.smNomenclaturePipe.transform(subtitle.EntityType, programId)),
                    EntityText: subtitle.EntityText,
                    IsLink: !noConfigLink && subtitle.OrderNum === lastSubtitleNum
                };
            });

            return {
                Id: notification.Id,
                NotificationType: <NotificationType>notification.NotificationType,
                Domain: domain,
                Message: this.asyncPipe.transform(this.guidReplacerPipe.transform(notification.Message)),
                Subtitles: subtitles,
                Description: notification.Description,
                ContextIds: notification.ContextIds,
                OriginatorBadge: this.tenantAssignableService.getAssignableAsBadge(notification.IdentityKey),
                CreatedOnDate: new Date(notification?.data?.CreatedTimeUTC),
                IsRead: notification.Alerts()[0].Read,
                IsMarkedForDelete: null
            };
        })

        return vms;
    }

    public onClickLink(vm: NotificationCardVM) {
        this.handleReadNotifications(vm);

        var subtitle = _.find(vm.Subtitles, s => { return s.IsLink; });
        var entries: RouteEntryItem[] = _.map(Object.getOwnPropertyNames(vm.ContextIds), entityType => {
            var entityId = vm.ContextIds[entityType];
            var entry: RouteEntryItem = {
                Type: entityType,
                Id: entityId
            };
            return entry;
        });

        var url = RouteUtils.BuildEntityRoute(subtitle.EntityType, entries)
        this.router.navigate([url]);
    }

    private getTenantUserNotifications(): void {
        if (!this.hasLoadedTenantNotifications) {
            this.tenantService.ListUserNotificationCenters(this.userContext);
            this.hasLoadedTenantNotifications = true;
        }

        if (this.authService.getAreaKey() && !this.hasLoadedAccountNotifications) {
            this.accountService.ListUserNotificationCenters(this.userContext);
            this.hasLoadedAccountNotifications = true;
        }
    }

    private buildUserBadge() {
        if (!(this.view == 'Admin')) {
            this.badge = {
                Type: "User",
                Id: this.user.Id,
                Name: this.user.DisplayName,
                Initials: this.user.Initials,
                Title: this.user.Title,
                Email: this.user.Email,
                Disabled: !this.user.Active
            };
        }
        else {
            this.badge = {
                Type: "User",
                Id: this.adminUser.Id,
                Name: this.adminUser.DisplayName,
                Initials: this.adminUser.DisplayName.charAt(0).toUpperCase(),
                Title: "",
                Email: this.adminUser.Logon,
                Disabled: !this.adminUser.IsActive
            }
        }
    }

    public get currentTenant(): string {
        if (this.authService.spaName == 'admin') {
            return "Admin";
        } else {
            return this.authService.getTenantKey();
        }
    }

    public get availableTenants(): string[] {
        if (this.authService.spaName == 'work'
            || this.authService.spaName == 'mgmt'
            || this.authService.spaName == 'conf') {
            return this.authService.getAvailableTenants();
        } else {
            return [];
        }
    }

    public onTenantClick(): void {

        const dialogOptions: SimpleDialogOptions = {

            message: "Are you sure you want to switch tenants?",
            title: 'Switch Tenants',
            buttons: {
                leftButton: {
                    label: 'Confirm',
                    action: function (activeModal: NgbActiveModal) {
                        activeModal.close('cancel');
                    }
                },
                rightButton: {
                    label: 'Cancel',
                    action: function (activeModal: NgbActiveModal) {
                        activeModal.dismiss('cancel button');
                    }
                }
            }
        };

        const modalRef = this.modalService.open(SmSimpleDialog, { backdrop: 'static', centered: true });
        modalRef.componentInstance.options = dialogOptions;

        modalRef.result.then(() => {
            this.router.navigateByUrl("");
        }, () => { });
    }

    public get currentAreaOrConfig(): string {
        if (this.authService.spaName == 'work' || this.authService.spaName == 'mgmt') {
            var areaKey = this.authService.getAreaKey();
            var area = this.filteredAreas.find(a => a.Name == areaKey);
            if (area) {
                var areaAliasOrName = area.Alias ?? area.Name;
                return areaAliasOrName;
            }
        } else if (this.authService.spaName == 'conf') {
            return 'Config';
        }
        return null;
    }

    public get availableAreas(): Area[] {
        if (this.authService.spaName == 'work' || this.authService.spaName == 'mgmt') {
            return this.filteredAreas;
        } else if (this.authService.spaName == 'conf') {
            return [];
        } else if (this.authService.spaName == 'admin') {
            return [];
        }
        return [];
    }

    public get hideMenu(): boolean {
        if (this.authService.spaName == 'work' || this.authService.spaName == 'mgmt')
            return !this.authService.getAreaKey();
        return (this.view == 'Admin');
    }

    public onAreaClick(): void {
        const modalRef = this.modalService.open(SmSelectAreaModalComponent, {
            ariaLabelledBy: 'modal-basic-title',
            backdrop: "static",
            windowClass: "no-bg"
        });
        const modalComponent = modalRef.componentInstance as SmSelectAreaModalComponent;

        var area = this.currentAreaOrConfig;
        modalComponent.selectedAreaAliasOrName = area;
        modalComponent.areas = this.filteredAreas;

        // The modal will handle the logic.
        modalRef.result.then(() => { })
            .catch(() => { });
    }

    public onLogoClick(): void {
        this.router.navigate([this.view]);
    }

    public closeNotifications() {
        if (this.notifyPopover?.isOpen()) {
            this.notifyPopover.close();
        }
    }

    public handleDeletedNotifications(): void {
        let deletedAccountAlerts: string[] = [];
        let deletedTenantAlerts: string[] = [];
        this.notificationCardVMs.forEach(notificationCardVM => {
            if (notificationCardVM.IsMarkedForDelete) {
                if (notificationCardVM.Domain == NotificationDomain.Account) {
                    deletedAccountAlerts.push(notificationCardVM.Id);
                } else if (notificationCardVM.Domain == NotificationDomain.Tenant) {
                    deletedTenantAlerts.push(notificationCardVM.Id);
                }
            }
        });

        if (deletedAccountAlerts.length > 0) {
            this.accountService.RemoveNotificationsForUser(deletedAccountAlerts, this.userContext);
        } 
        if (deletedTenantAlerts.length > 0) {
            this.tenantService.RemoveNotificationsForUser(deletedTenantAlerts, this.userContext);
        }

        this.closeNotifications();
    }

    public handleReadNotifications(notificationVM: NotificationCardVM = null): void {    
        if (notificationVM) {
            if (notificationVM.Domain == NotificationDomain.Account)
                this.accountService.MarkNotificationsAsRead([notificationVM.Id], this.userContext);
            if (notificationVM.Domain == NotificationDomain.Tenant)
                this.tenantService.MarkNotificationsAsRead([notificationVM.Id], this.userContext);
        }
        else {
            this.markAllNotificationsAsRead();
        }

        this.closeNotifications();
    }

    public markAllNotificationsAsRead(): void {
        const accountReadNotifications: string[] = [];
        const tenantReadNotifications: string[] = [];

        this.notificationCardVMs.forEach(notification => {
            if (notification.IsRead)
                return;

            notification.IsRead = true;

            if (notification.Domain == NotificationDomain.Account)
                accountReadNotifications.push(notification.Id);
            if (notification.Domain == NotificationDomain.Tenant)
                tenantReadNotifications.push(notification.Id);
        });

        if (accountReadNotifications.length > 0)
            this.accountService.MarkNotificationsAsRead(accountReadNotifications, this.userContext);
        if (tenantReadNotifications.length > 0)
            this.tenantService.MarkNotificationsAsRead(tenantReadNotifications, this.userContext);
    }

    public getUnreadNotificationsCount(): number {
        if (!this.notificationCardVMs) 
            return 0;

        return this.notificationCardVMs.filter(notification => notification.IsRead === false).length;
    };

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
};

export interface NotificationSubtitle {
    EntityType: string,
    EntityText: string,
    OrderNum: number
}