import { Component, Input } from '@angular/core';

@Component({
    selector: 'unity-badge',
    templateUrl: './unity-badge.component.html',
    styleUrls: ['./unity-badge.component.scss']
})
export class UnityBadgeComponent {

    @Input() type: UnityBadgeType = 'text';
    @Input() color: UnityBadgeColor = 'default';
    @Input() prominence: UnityBadgeProminence = 'high';

    // Note: This property only applies when type=notification
    @Input() size: UnityBadgeSize = '';

}

type UnityBadgeType = "text" | "count" | "notification" | "beacon";
type UnityBadgeColor = "default" | "info" | "success" | "warning" | "error" | "purple" | "cyan" | "ultramarine" | "pink";
type UnityBadgeProminence = "low" | "high";
type UnityBadgeSize = "" | "small" | "mini";