import { LoadingService } from '@Services/loading-service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@Services/auth-service';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [NgbTooltipConfig]
})
export class AppComponent {
    public loading = false;

    constructor(public authService: AuthService,
        private router: Router,
        private loadingService: LoadingService,
        private tooltipConfig: NgbTooltipConfig) {
        this.loadingService.loading.subscribe(l => {
            this.loading = l;
        });
        tooltipConfig.openDelay = 1000;
        tooltipConfig.container = 'body';
    }
}
