import { Component, Input } from '@angular/core';

@Component({
    selector: 'unity-icon',
    templateUrl: './unity-icon.component.html',
    styleUrls: ['./unity-icon.component.scss']
})
export class UnityIconComponent {
    @Input() icon: string;
    @Input() size: 'small'|'medium'|''|'large'|'xlarge' = '';
};
