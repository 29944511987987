import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import _ from 'lodash';

import { BaseService } from '@Services/base-service';
import { GlobalMessages } from '@Services/global-messages';
import { LoggingService } from '@Services/logging-service';
import { LoadingService } from '@Services/loading-service';
import { ITypedServiceResponse } from '@Core/Lib/model';

import { DataContext } from '@Core/Lib/Contexts/data-context';
import * as Models from '@Core/CodeGen/Models/area.models';

class Response extends HttpResponse<any> { }

@Injectable()
export class WorkflowsService extends BaseService {

    public static baseUrl: string = "";

    constructor(protected http: HttpClient,
                protected globalMessages: GlobalMessages,
                protected loggingService: LoggingService,
                protected loadingService: LoadingService) {
        super(globalMessages, loggingService, loadingService);
        WorkflowsService.baseUrl = BaseService.baseUrl + "/Workflows/";
    }

    public ListAssets(workflowSetId: string,
        optionalParameters: {
            productId?: string,
            assetGroupId?: string,
            tags?: string,
            assetName?: string,
            assetGroupName?: string
        }): Observable<Response> {
        // Build the url
        var url = WorkflowsService.baseUrl + "WorkflowSets/{workflowSetId}/Assets";
        url = url.replace("{workflowSetId}", workflowSetId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.productId != null)
            params = params.set('productId', optionalParameters.productId);
        if (optionalParameters?.assetGroupId != null)
            params = params.set('assetGroupId', optionalParameters.assetGroupId);
        if (optionalParameters?.tags != null)
            params = params.set('tags', optionalParameters.tags);
        if (optionalParameters?.assetName != null)
            params = params.set('assetName', optionalParameters.assetName);
        if (optionalParameters?.assetGroupName != null)
            params = params.set('assetGroupName', optionalParameters.assetGroupName);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public RetrieveAsset(workflowSetId: string, assetId: string): Observable<Response> {
        // Build the url
        var url = WorkflowsService.baseUrl + "WorkflowSets/{workflowSetId}/Assets/{assetId}";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{assetId}", assetId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public RetrieveAssetContent(workflowSetId: string, assetId: string): Observable<Response> {
        // Build the url
        var url = WorkflowsService.baseUrl + "WorkflowSets/{workflowSetId}/Assets/{assetId}/Content";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{assetId}", assetId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ListPhases(contexts: DataContext[] | DataContext,
        optionalParameters: {
            userId?: string,
            accountId?: string
        }): Observable<ITypedServiceResponse<Models.Phase>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "Phases";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.userId != null)
            params = params.set('userId', optionalParameters.userId);
        if (optionalParameters?.accountId != null)
            params = params.set('accountId', optionalParameters.accountId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Phase, request, context);
        });
        return request;
    }

    public RetrievePhase(contexts: DataContext[] | DataContext, workflowSetId: string, phaseId: string,
        optionalParameters: {
            includeTaskDetails?: string
        }): Observable<ITypedServiceResponse<Models.Phase>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Phases/{phaseId}";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{phaseId}", phaseId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.includeTaskDetails != null)
            params = params.set('includeTaskDetails', optionalParameters.includeTaskDetails);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Phase, request, context);
        });
        return request;
    }

    public ModifyPhase(body: any, contexts: DataContext[] | DataContext, workflowSetId: string, phaseId: string): Observable<ITypedServiceResponse<Models.Phase>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Phases/{phaseId}";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{phaseId}", phaseId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Phase, request, context);
        });
        return request;
    }

    public ListAllPrograms(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "Programs";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Program, request, context);
        });
        return request;
    }

    public ListAllProgramsRevisions(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.ProgramRevision>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "Programs/Revisions";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.ProgramRevision, request, context);
        });
        return request;
    }

    public ListModelsOfAProgramRevision(revisionId: string): Observable<Response> {
        // Build the url
        var url = WorkflowsService.baseUrl + "Programs/Revisions/{revisionId}";
        url = url.replace("{revisionId}", revisionId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ListTasks(contexts: DataContext[] | DataContext,
        optionalParameters: {
            userId?: string,
            accountId?: string,
            workflowSetId?: string,
            workflowId?: string,
            includePhaseDetails?: string
        }): Observable<ITypedServiceResponse<Models.Task>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "Tasks";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.userId != null)
            params = params.set('userId', optionalParameters.userId);
        if (optionalParameters?.accountId != null)
            params = params.set('accountId', optionalParameters.accountId);
        if (optionalParameters?.workflowSetId != null)
            params = params.set('workflowSetId', optionalParameters.workflowSetId);
        if (optionalParameters?.workflowId != null)
            params = params.set('workflowId', optionalParameters.workflowId);
        if (optionalParameters?.includePhaseDetails != null)
            params = params.set('includePhaseDetails', optionalParameters.includePhaseDetails);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Task, request, context);
        });
        return request;
    }

    public CreateTask(body: any, contexts: DataContext[] | DataContext, workflowSetId: string): Observable<ITypedServiceResponse<Models.Task>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Tasks";
        url = url.replace("{workflowSetId}", workflowSetId);

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Task, request, context);
        });
        return request;
    }

    public ModifyTask(body: any, contexts: DataContext[] | DataContext, workflowSetId: string, taskId: string): Observable<ITypedServiceResponse<Models.Task>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Tasks/{taskId}";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{taskId}", taskId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Task, request, context);
        });
        return request;
    }

    public DelegateTask(body: any, contexts: DataContext[] | DataContext, workflowSetId: string, taskId: string, assignableId: string): Observable<ITypedServiceResponse<Models.Task>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Tasks/{taskId}/Assign/{assignableId}";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{taskId}", taskId);
        url = url.replace("{assignableId}", assignableId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Task, request, context);
        });
        return request;
    }

    public LockTasks(body: any, contexts: DataContext[] | DataContext, workflowSetId: string): Observable<ITypedServiceResponse<Models.Task>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Tasks/Lock";
        url = url.replace("{workflowSetId}", workflowSetId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Task, request, context);
        });
        return request;
    }

    public StealATaskLock(body: any, contexts: DataContext[] | DataContext, workflowSetId: string, taskId: string): Observable<ITypedServiceResponse<Models.Task>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Tasks/Lock/{taskId}";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{taskId}", taskId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Task, request, context);
        });
        return request;
    }

    public UnlockTasks(body: any, contexts: DataContext[] | DataContext, workflowSetId: string): Observable<ITypedServiceResponse<Models.Task>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Tasks/Unlock";
        url = url.replace("{workflowSetId}", workflowSetId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Task, request, context);
        });
        return request;
    }

    public ExecuteTask(body: any, contexts: DataContext[] | DataContext, workflowSetId: string, taskId: string): Observable<ITypedServiceResponse<Models.Task>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Tasks/{taskId}/Execute";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{taskId}", taskId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Task, request, context);
        });
        return request;
    }

    public StopExecutingATask(body: any, contexts: DataContext[] | DataContext, workflowSetId: string, taskId: string): Observable<ITypedServiceResponse<Models.Task>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Tasks/{taskId}/StopExecuting";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{taskId}", taskId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Task, request, context);
        });
        return request;
    }

    public CompleteTasks(body: any, contexts: DataContext[] | DataContext, workflowSetId: string): Observable<ITypedServiceResponse<Models.Task>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Tasks/Complete";
        url = url.replace("{workflowSetId}", workflowSetId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Task, request, context);
        });
        return request;
    }

    public RetrieveTask(contexts: DataContext[] | DataContext, workflowSetId: string, taskId: string): Observable<ITypedServiceResponse<Models.Task>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Tasks/{taskId}";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{taskId}", taskId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Task, request, context);
        });
        return request;
    }

    public WaiveTask(body: any, contexts: DataContext[] | DataContext, workflowSetId: string, taskId: string, waiveJournalEntryId: string): Observable<ITypedServiceResponse<Models.Task>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Tasks/{taskId}/Waive";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{taskId}", taskId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('waiveJournalEntryId', waiveJournalEntryId);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Task, request, context);
        });
        return request;
    }

    public UndoWaivedTask(body: any, contexts: DataContext[] | DataContext, workflowSetId: string, taskId: string): Observable<ITypedServiceResponse<Models.Task>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Tasks/{taskId}/UndoWaive";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{taskId}", taskId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Task, request, context);
        });
        return request;
    }

    public RedoTask(body: any, workflowSetId: string, taskId: string): Observable<Response> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Tasks/{taskId}/Redo";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{taskId}", taskId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public DeferTask(body: any, workflowSetId: string, taskId: string): Observable<Response> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Tasks/{taskId}/Defer";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{taskId}", taskId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ListUsers(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.SMIdentity>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "TenantUsers";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.SMIdentity, request, context);
        });
        return request;
    }

    public ListUserOrAccountWorkflows(contexts: DataContext[] | DataContext,
        optionalParameters: {
            userId?: string,
            accountId?: string
        }): Observable<ITypedServiceResponse<Models.Workflow>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.userId != null)
            params = params.set('userId', optionalParameters.userId);
        if (optionalParameters?.accountId != null)
            params = params.set('accountId', optionalParameters.accountId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Workflow, request, context);
        });
        return request;
    }

    public RetrieveWorkflow(contexts: DataContext[] | DataContext, workflowSetId: string, workflowId: string): Observable<ITypedServiceResponse<Models.Workflow>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Workflows/{workflowId}";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{workflowId}", workflowId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Workflow, request, context);
        });
        return request;
    }

    public ModifyWorkflow(body: any, contexts: DataContext[] | DataContext, workflowSetId: string, workflowId: string): Observable<ITypedServiceResponse<Models.Workflow>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Workflows/{workflowId}";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{workflowId}", workflowId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Workflow, request, context);
        });
        return request;
    }

    public UpdateWorkflowEffectiveDate(body: any, contexts: DataContext[] | DataContext, workflowSetId: string, workflowId: string): Observable<ITypedServiceResponse<Models.Workflow>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Workflows/{workflowId}/EffectiveDate";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{workflowId}", workflowId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Workflow, request, context);
        });
        return request;
    }

    public SelectAWorkflowQuoteVersion(body: any, contexts: DataContext[] | DataContext, workflowSetId: string, workflowId: string): Observable<ITypedServiceResponse<Models.Workflow>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Workflows/{workflowId}/SelectVersion";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{workflowId}", workflowId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Workflow, request, context);
        });
        return request;
    }

    public ListStartingWorkflows(contexts: DataContext[] | DataContext, proposedEffectiveDate: string, programId: string): Observable<ITypedServiceResponse<Models.Workflow>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "Starting";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('proposedEffectiveDate', proposedEffectiveDate);
        params = params.set('programId', programId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Workflow, request, context);
        });
        return request;
    }

    public ListAvailableProgramWorkflows(workflowSetId: string, accountId: string): Observable<Response> {
        // Build the url
        var url = WorkflowsService.baseUrl + "WorkflowSets/{workflowSetId}/Available";
        url = url.replace("{workflowSetId}", workflowSetId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('accountId', accountId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public StartWorkflow(body: any, contexts: DataContext[] | DataContext,
        optionalParameters: {
            browserMode?: string
        }): Observable<ITypedServiceResponse<Models.Workflow>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.browserMode != null)
            params = params.set('browserMode', optionalParameters.browserMode);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Workflow, request, context);
        });
        return request;
    }

    public Decline(body: any, contexts: DataContext[] | DataContext, workflowSetId: string, workflowId: string): Observable<ITypedServiceResponse<Models.Workflow>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Workflows/{workflowId}/Decline";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{workflowId}", workflowId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Workflow, request, context);
        });
        return request;
    }

    public CloseWorkflow(body: any, contexts: DataContext[] | DataContext, workflowSetId: string, workflowId: string): Observable<ITypedServiceResponse<Models.Workflow>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Workflows/{workflowId}/Close";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{workflowId}", workflowId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Workflow, request, context);
        });
        return request;
    }

    public StopWorkflow(body: any, contexts: DataContext[] | DataContext, workflowSetId: string, workflowId: string): Observable<ITypedServiceResponse<Models.Workflow>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Workflows/{workflowId}/Stop";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{workflowId}", workflowId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Workflow, request, context);
        });
        return request;
    }

    public RestartWorkflow(body: any, contexts: DataContext[] | DataContext, workflowSetId: string, workflowId: string): Observable<ITypedServiceResponse<Models.Workflow>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Workflows/{workflowId}/Restart";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{workflowId}", workflowId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Workflow, request, context);
        });
        return request;
    }

    public RetrieveRevisionForWorkflow(contexts: DataContext[] | DataContext, workflowSetId: string, workflowId: string): Observable<ITypedServiceResponse<Models.ProgramRevision>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Workflows/{workflowId}/Revision";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{workflowId}", workflowId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.ProgramRevision, request, context);
        });
        return request;
    }

    public ListAccountRoles(contexts: DataContext[] | DataContext, accountId: string): Observable<ITypedServiceResponse<Models.AccountRole>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "AccountRoles";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('accountId', accountId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.AccountRole, request, context);
        });
        return request;
    }

    public OverrideWorkflowsetStatus(body: any, contexts: DataContext[] | DataContext, workflowSetId: string): Observable<ITypedServiceResponse<Models.WorkflowSet>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/OverrideWorkflowSetStatus";
        url = url.replace("{workflowSetId}", workflowSetId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.WorkflowSet, request, context);
        });
        return request;
    }

    public BuildAWorkflow(body: any, contexts: DataContext[] | DataContext, workflowSetId: string, workflowId: string): Observable<ITypedServiceResponse<Models.Workflow>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Workflows/{workflowId}/Build";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{workflowId}", workflowId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Workflow, request, context);
        });
        return request;
    }

    public CopyAWorkflow(body: any, contexts: DataContext[] | DataContext, workflowSetId: string, workflowId: string): Observable<ITypedServiceResponse<Models.Workflow>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}/Workflows/{workflowId}/Copy";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{workflowId}", workflowId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Workflow, request, context);
        });
        return request;
    }

    public RetrieveWorkflowSet(contexts: DataContext[] | DataContext, workflowSetId: string): Observable<ITypedServiceResponse<Models.WorkflowSet>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "{workflowSetId}";
        url = url.replace("{workflowSetId}", workflowSetId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.WorkflowSet, request, context);
        });
        return request;
    }

    public ListWorkGroups(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.WorkGroup>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "WorkGroups";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.WorkGroup, request, context);
        });
        return request;
    }

    public RetrieveReservationset(contexts: DataContext[] | DataContext, reservationSetId: string): Observable<ITypedServiceResponse<Models.ReservationSet>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "ReservationSets/{reservationSetId}";
        url = url.replace("{reservationSetId}", reservationSetId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.ReservationSet, request, context);
        });
        return request;
    }

    public ListReservationsets(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.ReservationSet>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "ReservationSets";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.ReservationSet, request, context);
        });
        return request;
    }

    public StartWorkingWorkGroup(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.ReservationSet>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "ReservationSets";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ReservationSet, request, context);
        });
        return request;
    }

    public ReserveAccount(body: any, reservationSetId: string,
        optionalParameters: {
            includeTasksInResponse?: string
        }): Observable<Response> {
        // Build the url
        var url = WorkflowsService.baseUrl + "ReservationSets/{reservationSetId}/Reserve";
        url = url.replace("{reservationSetId}", reservationSetId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.includeTasksInResponse != null)
            params = params.set('includeTasksInResponse', optionalParameters.includeTasksInResponse);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public StopWorkingWorkGroup(idOfDeletedItem: string, contexts: DataContext[] | DataContext, reservationSetId: string): Observable<Response> {
        // Build the url
        var url = WorkflowsService.baseUrl + "ReservationSets/{reservationSetId}";
        url = url.replace("{reservationSetId}", reservationSetId);

        // Make the request
        let request = this.http.delete<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public RetrieveAWorkgroup(contexts: DataContext[] | DataContext, workGroupId: string,
        optionalParameters: {
            updateNumUnreservedAccounts?: string
        }): Observable<ITypedServiceResponse<Models.WorkGroup>> {
        // Build the url
        var url = WorkflowsService.baseUrl + "WorkGroups/{workGroupId}";
        url = url.replace("{workGroupId}", workGroupId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.updateNumUnreservedAccounts != null)
            params = params.set('updateNumUnreservedAccounts', optionalParameters.updateNumUnreservedAccounts);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.WorkGroup, request, context);
        });
        return request;
    }

}