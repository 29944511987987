import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UserNotificationCenter } from "@Core/CodeGen/Models/configuration.models";
import { TenantService } from "@Core/CodeGen/Services/tenant.service";
import { TenantContext } from "@Core/Lib/Contexts/tenant-context";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "@Services/auth-service";
import { NotificationType } from "@Core/CodeGen/Eums/library.enums";

@Component({
    selector: 'sm-notification-settings',
    templateUrl: './sm-notification-settings.component.html',
    styleUrls: ['./sm-notification-settings.component.scss']
})
export class SmNotificationSettingsComponent {

    public model: UserNotificationCenterVM;

    public expirationOptions = [
        { value: 7, label: '7 days' },
        { value: 14, label: '14 days' },
        { value: 30, label: '30 days' }
    ];

    constructor(
        public activeModal: NgbActiveModal,
        public tenantService: TenantService,
        public tenantContext: TenantContext,
        public authService: AuthService
    ) {
        var userNotificationCenters: BehaviorSubject<UserNotificationCenter[]> =
        this.tenantContext.getStore<UserNotificationCenter>(new UserNotificationCenter()).values;

        userNotificationCenters.subscribe(values => {
            var unc = values.find(un => un.IdentityKey == authService.getIdentityKey());
            if (unc) {

                this.model = {
                    Id: unc.Id,
                    IdentityKey: unc.IdentityKey,
                    EnableInAppNotificationPopups: unc.EnableInAppNotificationPopups,
                    ExpirationDurationOfReadNotificationsInDays: unc.ExpirationDurationOfReadNotificationsInDays,
                    CommentNotifications: true,
                    TaskDelegationNotifications: false,
                    ProgramRevisionNotifications: true,
                    JournalEntryNotifications: true,
                    EmailNotifications: true
                };

                if (unc.NotificationTypeSettings) {
                    this.model.CommentNotifications = unc.NotificationTypeSettings[NotificationType.COMMENT] !== false;
                    this.model.TaskDelegationNotifications = unc.NotificationTypeSettings[NotificationType.TASK_DELEGATION] !== false;
                    this.model.ProgramRevisionNotifications = unc.NotificationTypeSettings[NotificationType.PROGRAM_REVISION] !== false;
                    this.model.JournalEntryNotifications = unc.NotificationTypeSettings[NotificationType.JOURNAL_ENTRY] !== false;
                    this.model.EmailNotifications = unc.NotificationTypeSettings[NotificationType.EMAIL] !== false;
                }
            }
        });
    }

    toggleInAppNotificationPopups() {
        this.model.EnableInAppNotificationPopups = !this.model.EnableInAppNotificationPopups;
    }

    save() {
        var userNotificationCenterToSave = new UserNotificationCenter();
        userNotificationCenterToSave.IdentityKey = this.model.IdentityKey;
        userNotificationCenterToSave.Id = this.model.Id;
        userNotificationCenterToSave.EnableInAppNotificationPopups = this.model.EnableInAppNotificationPopups;
        userNotificationCenterToSave.ExpirationDurationOfReadNotificationsInDays =
            parseInt(this.model.ExpirationDurationOfReadNotificationsInDays.toString());
        userNotificationCenterToSave.NotificationTypeSettings = {
            "ProgramRevision" : this.model.ProgramRevisionNotifications,
            "Comment" : this.model.CommentNotifications,
            "TaskDelegation" : this.model.TaskDelegationNotifications,
            "JournalEntry" : this.model.JournalEntryNotifications,
            "Email": this.model.EmailNotifications
        }

        this.tenantService.UpdateUserNotificationSettings(userNotificationCenterToSave.serialize(), this.tenantContext);
        this.activeModal.close();
    }

    cancel() {
        this.activeModal.dismiss();
    }
}

export interface UserNotificationCenterVM {
    Id: string;
    IdentityKey: string;
    EnableInAppNotificationPopups: boolean;
    JournalEntryNotifications: boolean;
    CommentNotifications: boolean;
    TaskDelegationNotifications: boolean;
    ProgramRevisionNotifications: boolean;
    EmailNotifications: boolean;
    ExpirationDurationOfReadNotificationsInDays: number;
}
