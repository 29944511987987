@if (showAvatar()) {
    <unity-avatar id="notificationAvatar" [model]="(vm?.OriginatorBadge | async)"></unity-avatar>
} @else {
    <unity-icon [icon]="getAvatarIcon()" size="large"/>
}

<h2 id="notificationTitle">{{ vm?.Message }}</h2>

<div *ngIf="!vm?.IsMarkedForDelete" class="time_actions">
    <sm-time-since class="card-title-time-since" [start]="vm?.CreatedOnDate"></sm-time-since>
    <button id="deleteNotification" unity-button class="icon-only small" (click)="onDeleteNotification()">
        <svg class="unity-icon-trash">
            <use xlink:href="#unity-icon-trash"></use>
        </svg>
    </button>
</div>

<button id="undoDeleteNotification" unity-button *ngIf="vm?.IsMarkedForDelete" class="link small undo" (click)="onUndoDelete()">
    undo delete
    <svg class="unity-icon-undo">
        <use xlink:href="#unity-icon-undo"></use>
    </svg>
</button>

<ng-container *ngFor="let subtitle of vm.Subtitles">
    <div *ngIf="!vm?.IsMarkedForDelete" id="notificationEntity" class="subline">
        {{ subtitle?.DisplayEntityType ?? subtitle?.EntityType }}: 
        <button 
            unity-button 
            *ngIf="subtitle?.IsLink" 
            [id]="'notificationEntityLink:' + subtitle.EntityType + ':' + subtitle.EntityId" 
            class="link secondary" 
            (click)="onClickLink.emit(vm)"
        >
            {{subtitle?.EntityText || 'Go to ' + (subtitle?.DisplayEntityType ?? subtitle?.EntityType) }}
        </button>
        <ng-container *ngIf="!subtitle?.IsLink">{{subtitle?.EntityText}}</ng-container>
    </div>
</ng-container>

<div 
    *ngIf="vm?.Description && !vm?.IsMarkedForDelete" 
    class="description" [ngClass]="{ 'overflow': needsShowMoreShowLess}" 
    [class.collapsed]="isCollapsed" 
    [class.overflow]="isOverflow" 
    (click)="toggleDescription()"
>
    <span id="notificationDescription">{{vm?.Description}}</span>
</div>
