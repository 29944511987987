import { Component, Input } from "@angular/core";

@Component({
    selector: 'sm-help-tooltip',
    templateUrl: './sm-help-tooltip.component.html',
    styleUrls: ['./sm-help-tooltip.component.scss']
})
export class SmHelpTooltipComponent {
    @Input() title?: string;
    @Input() placement?: Placements = Placements.Auto;
    @Input() icon?: Icon = Icon.Help;
    @Input() color?: Color = Color.Black;
    @Input() trigger?: Triggers = Triggers.Click;

    @Input() bgColor?: Color = null;
};

export enum Icon {
    Help = 'help',
    Info = 'information',
    Warning = 'warning',
    Error = 'error'
}

export enum Color {
    Black = 'black',
    White = 'white',
}

export enum Placements {
    Auto = 'auto',
    Top = 'top',
    TopLeft = 'top-left',
    TopRight = 'top-right',
    Bottom = 'bottom',
    BottomLeft = 'bottom-left',
    BottomRight = 'bottom-right',
    Left = 'left',
    LeftTop = 'left-top',
    LeftBottom = 'left-bottom',
    Right = 'right',
    RightTop = 'right-top',
    RightBottom = 'right-bottom',
}

export enum Triggers {
    Click = 'click',
    Hover = 'hover',
    Focus = 'focus',
    Manual = 'manual',
}
