import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import _ from "lodash";
import { SmFormFieldVM } from "./sm-form-field.component";
import { SmFormSectionVM } from "./sm-form-section.component";
import { SmFormService } from "./sm-form-service";

@Component({
    selector: 'sm-form',
    templateUrl: './sm-form.component.html',
    styleUrls: ['./sm-form.component.scss']
})
export class SmFormComponent  {
    @Input() model: SmFormVM;
    @Input() readOnly: boolean;
    @Input() formState: SmFormState;

    @Output() onValueChanged: EventEmitter<FormValueChangeEvent> = new EventEmitter();
    @Output() onSectionAdded: EventEmitter<SmFormSectionVM> = new EventEmitter();
    @Output() onSectionDeleted: EventEmitter<SectionDeletedEvent> = new EventEmitter();

    public hideSingleSectionArrow: boolean;

    constructor(private formService: SmFormService,
        private cdRef: ChangeDetectorRef) {}

    ngOnInit() {
        this.hideSingleSectionArrow = this.model.Sections.length > 1 ? false : true;
    }

    public focusElement(id: string, type: 'Field' | 'Section' | 'ParentSection') {
        // First, make sure we expand everything necessary to show the element
        this.formService.expandAllAncestorsOfElement(this.model, id);
        this.cdRef.detectChanges();
        
        // Then, send a message to focus the element
        this.formService.sendElementFocused(id, type);
    }
}

export interface SmFormVM {
    Id: string; // The Id of the App
    Sections: SmFormSectionVM[];
}

export interface SmFormElementVM {
    Id: string;
    Label: string;
    PartitionKey: string;
    ElementType: 'Section' | 'Field' | 'StaticText' | 'Row'; // Note: Row is not actually an 'Element' yet, 
                                                             // so some of these properties won't apply to a Row. 
    CreatedFromId: string;
    Description?: string;
    Hidden?: boolean; // Used for conditionally hiding elements. 
    Errors?: string[];
}

export interface FormValueChangeEvent {
    FieldVM: SmFormFieldVM;
    NewValue: any;
    Type?: 'input' | 'change';
}

export interface SectionDeletedEvent {
    InstanceId: string;
    InstancePartitionKey: string;
    ContainingSectionId: string;
}

export interface SectionAddedEvent {
    InstanceId: string;
    ContainingSectionId: string;
}

export interface SmFormState {
    Edition: number;
    Currency: string;
    IsStartingOrCopy: boolean;
}