<unity-modal
        title="Notification Settings"
        primaryAction="Save"
        secondaryAction="Cancel"
        (onPrimaryAction)="save()"
        (onClose)="cancel()"
        (onSecondaryAction)="cancel()"
>

    <unity-loading-indicator *ngIf="!model" />

    <ng-container *ngIf="model">
        <div class="form-group enable_popups">
            <unity-toggle
                    controlName="enableInAppNotificationPopups"
                    [(toggledOn)]="model.EnableInAppNotificationPopups"
                    (onToggle)="toggleInAppNotificationPopups()"
            ></unity-toggle>
            <label for="enableInAppNotificationPopups">Enable in-app notification popups</label>
        </div>

        <div class="form-group">
            <h2 class="mb-2">Notification Types</h2>
            <div class="form-check">
                <input
                        class="form-check-input"
                        type="checkbox"
                        name="journalEntryNotifications"
                        id="journalEntryNotifications"
                        [(ngModel)]="model.JournalEntryNotifications"
                >
                <label class="form-check-label" for="journalEntryNotifications">
                    Account Owner - Journal Entries
                </label>
            </div>
            <div class="form-check">
                <input
                        class="form-check-input"
                        type="checkbox"
                        name="comments"
                        id="comments"
                        [(ngModel)]="model.CommentNotifications"
                >
                <label class="form-check-label" for="comments">
                    Comments
                </label>
            </div>
            <div class="form-check">
                <input
                        class="form-check-input"
                        type="checkbox"
                        name="delegation"
                        id="delegation"
                        [(ngModel)]="model.TaskDelegationNotifications"
                >
                <label class="form-check-label" for="delegation">
                    Task Delegation
                </label>
            </div>
            <div class="form-check">
                <input
                        class="form-check-input"
                        type="checkbox"
                        name="programRevisionNotifications"
                        id="programRevisionNotifications"
                        [(ngModel)]="model.ProgramRevisionNotifications"
                >
                <label class="form-check-label" for="programRevisionNotifications">
                    Program Revision
                </label>
            </div>
            <div class="form-check">
                <input
                        class="form-check-input"
                        type="checkbox"
                        name="emailNotifications"
                        id="emailNotifications"
                        [(ngModel)]="model.EmailNotifications"
                >
                <label class="form-check-label" for="emailNotifications">
                    Account Owner - New Email
                </label>
            </div>
        </div>

        <div class="form-group">
            <label class="form-check-label" for="programRevisionNotifications">Remove read notifications
                after</label>
            <select [(ngModel)]="model.ExpirationDurationOfReadNotificationsInDays" class="form-select">
                <option *ngFor="let option of expirationOptions" [value]="option.value">
                    {{option.label}}
                </option>
            </select>
        </div>

    </ng-container>

</unity-modal>
