<form class="card">
    <h3>Choose an Area</h3>
    <select name="areaSelect" [(ngModel)]="selectedArea" class="form-select mt-2" (ngModelChange)="areaSelected()">
        <option *ngFor="let area of filteredAreas" [ngValue]="area">{{area.Alias || area.Name}}</option>
    </select>
    <button unity-button
            name="SelectAreaButton"
            [disabled]="filteredAreas?.length === 0"
            (click)="areaSelect()"
            class="primary block mt-2">
        Go
    </button>
    <button unity-button
            *ngIf="isModal"
            (click)="onCancel.emit()"
            class="ghost block on_dark mt-3">
        Cancel
    </button>
    <ng-container *ngIf="!isModal">
        <button unity-button
                *sm-has-all-permission="[
                    TENANT_PERMISSIONS.SystemAccess,
                    TENANT_PERMISSIONS.Tenant,
                    TENANT_PERMISSIONS.Programs
                ]"
                class="link on_dark block mt-3"
                (click)="goToConfig()">
            Go to Configure
        </button>
    </ng-container>
</form>
