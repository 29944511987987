import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import _ from 'lodash';

import { BaseService } from '@Services/base-service';
import { GlobalMessages } from '@Services/global-messages';
import { LoggingService } from '@Services/logging-service';
import { LoadingService } from '@Services/loading-service';
import { ITypedServiceResponse } from '@Core/Lib/model';

import { DataContext } from '@Core/Lib/Contexts/data-context';
import * as Models from '@Core/CodeGen/Models/area.models';

class Response extends HttpResponse<any> { }

@Injectable()
export class AccountsService extends BaseService {

    public static baseUrl: string = "";

    constructor(protected http: HttpClient,
                protected globalMessages: GlobalMessages,
                protected loggingService: LoggingService,
                protected loadingService: LoadingService) {
        super(globalMessages, loggingService, loadingService);
        AccountsService.baseUrl = BaseService.baseUrl + "/Accounts/";
    }

    public AssignAccountRole(body: any, contexts: DataContext[] | DataContext, accountId: string, accountRoleId: string,
        optionalParameters: {
            identityId?: string
        }): Observable<ITypedServiceResponse<Models.AccountRole>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountId}/AccountRoles/{accountRoleId}/Assign";
        url = url.replace("{accountId}", accountId);
        url = url.replace("{accountRoleId}", accountRoleId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.identityId != null)
            params = params.set('identityId', optionalParameters.identityId);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.AccountRole, request, context);
        });
        return request;
    }

    public AddAccount(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Account>> {
        // Build the url
        var url = AccountsService.baseUrl + "";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Account, request, context);
        });
        return request;
    }

    public ListAccountsAndOptionallyAsListOfTasks(optionalParameters: {
            producerId?: string,
            view?: string,
            includeTasks?: string,
            numAccounts?: string
        }): Observable<Response> {
        // Build the url
        var url = AccountsService.baseUrl + "";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.producerId != null)
            params = params.set('producerId', optionalParameters.producerId);
        if (optionalParameters?.view != null)
            params = params.set('view', optionalParameters.view);
        if (optionalParameters?.includeTasks != null)
            params = params.set('includeTasks', optionalParameters.includeTasks);
        if (optionalParameters?.numAccounts != null)
            params = params.set('numAccounts', optionalParameters.numAccounts);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public SetNextAccountCodeNumber(body: any): Observable<Response> {
        // Build the url
        var url = AccountsService.baseUrl + "Accounts/NextCodeNumber";

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ChangeAccountProducer(body: any, contexts: DataContext[] | DataContext, accountId: string): Observable<ITypedServiceResponse<Models.Account>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountId}/Producer";
        url = url.replace("{accountId}", accountId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Account, request, context);
        });
        return request;
    }

    public ClearAccount(body: any, accountId: string): Observable<Response> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountId}/Clear";
        url = url.replace("{accountId}", accountId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public UpdateAccountBalance(body: any, contexts: DataContext[] | DataContext, accountIdOrCode: string): Observable<ITypedServiceResponse<Models.Account>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}/Balance";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Account, request, context);
        });
        return request;
    }

    public UpdateAccountTags(body: any, contexts: DataContext[] | DataContext, accountIdOrCode: string): Observable<ITypedServiceResponse<Models.Account>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}/UpdateTags";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Account, request, context);
        });
        return request;
    }

    public SearchAccounts(searchTerms: string,
        optionalParameters: {
            producerId?: string
        }): Observable<Response> {
        // Build the url
        var url = AccountsService.baseUrl + "Search";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('searchTerms', searchTerms);
        if (optionalParameters?.producerId != null)
            params = params.set('producerId', optionalParameters.producerId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public RetrieveAccountByIdOrCode(contexts: DataContext[] | DataContext, accountIdOrCode: string,
        optionalParameters: {
            includePoliciesAndTransactions?: string
        }): Observable<ITypedServiceResponse<Models.Account>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.includePoliciesAndTransactions != null)
            params = params.set('includePoliciesAndTransactions', optionalParameters.includePoliciesAndTransactions);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Account, request, context);
        });
        return request;
    }

    public ListWorkflowSets(contexts: DataContext[] | DataContext, accountId: string): Observable<ITypedServiceResponse<Models.WorkflowSet>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountId}/WorkflowSets";
        url = url.replace("{accountId}", accountId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.WorkflowSet, request, context);
        });
        return request;
    }

    public ListAccountAssignables(contexts: DataContext[] | DataContext, accountId: string): Observable<ITypedServiceResponse<Models.Assignable>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountId}/Assignables";
        url = url.replace("{accountId}", accountId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Assignable, request, context);
        });
        return request;
    }

    public FilterAccounts(body: any, activeAccounts: string): Observable<Response> {
        // Build the url
        var url = AccountsService.baseUrl + "Filter";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('activeAccounts', activeAccounts);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public BulkAccountRoleReassignment(body: any): Observable<Response> {
        // Build the url
        var url = AccountsService.baseUrl + "BulkReassign";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public RetrieveStatusOfBulkAccountRoleReassignment(operationId: string): Observable<Response> {
        // Build the url
        var url = AccountsService.baseUrl + "BulkReassign/{operationId}";
        url = url.replace("{operationId}", operationId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public GetLoggedInUserActivity(contexts: DataContext[] | DataContext,
        optionalParameters: {
            accountId?: string,
            entityId?: string
        }): Observable<ITypedServiceResponse<Models.AccountActivity>> {
        // Build the url
        var url = AccountsService.baseUrl + "Activities";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.accountId != null)
            params = params.set('accountId', optionalParameters.accountId);
        if (optionalParameters?.entityId != null)
            params = params.set('entityId', optionalParameters.entityId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.AccountActivity, request, context);
        });
        return request;
    }

    public RetrieveJournalEntries(contexts: DataContext[] | DataContext, accountId: string,
        optionalParameters: {
            correlationKey?: string,
            taskId?: string
        }): Observable<ITypedServiceResponse<Models.AccountJournalEntry>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountId}/JournalEntries";
        url = url.replace("{accountId}", accountId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.correlationKey != null)
            params = params.set('correlationKey', optionalParameters.correlationKey);
        if (optionalParameters?.taskId != null)
            params = params.set('taskId', optionalParameters.taskId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.AccountJournalEntry, request, context);
        });
        return request;
    }

    public RetrieveJournalEntry(contexts: DataContext[] | DataContext, accountId: string, journalId: string): Observable<ITypedServiceResponse<Models.AccountJournalEntry>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountId}/JournalEntries/{journalId}";
        url = url.replace("{accountId}", accountId);
        url = url.replace("{journalId}", journalId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.AccountJournalEntry, request, context);
        });
        return request;
    }

    public AddJournalEntry(body: any, contexts: DataContext[] | DataContext, accountId: string): Observable<ITypedServiceResponse<Models.AccountJournalEntry>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountId}/JournalEntries";
        url = url.replace("{accountId}", accountId);

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.AccountJournalEntry, request, context);
        });
        return request;
    }

    public UpdateJournalEntry(body: any, contexts: DataContext[] | DataContext, accountId: string, idOrCorrelationKey: string): Observable<ITypedServiceResponse<Models.AccountJournalEntry>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountId}/JournalEntries/{idOrCorrelationKey}";
        url = url.replace("{accountId}", accountId);
        url = url.replace("{idOrCorrelationKey}", idOrCorrelationKey);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.AccountJournalEntry, request, context);
        });
        return request;
    }

    public RetrieveAllVersionOfCommentsForAnAccount(contexts: DataContext[] | DataContext, accountId: string,
        optionalParameters: {
            correlationKey?: string,
            taskId?: string,
            journalEntryCorrelationKey?: string
        }): Observable<ITypedServiceResponse<Models.AccountComment>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountId}/Comments";
        url = url.replace("{accountId}", accountId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.correlationKey != null)
            params = params.set('correlationKey', optionalParameters.correlationKey);
        if (optionalParameters?.taskId != null)
            params = params.set('taskId', optionalParameters.taskId);
        if (optionalParameters?.journalEntryCorrelationKey != null)
            params = params.set('journalEntryCorrelationKey', optionalParameters.journalEntryCorrelationKey);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.AccountComment, request, context);
        });
        return request;
    }

    public RetrieveComment(contexts: DataContext[] | DataContext, accountId: string, commentId: string): Observable<ITypedServiceResponse<Models.AccountComment>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountId}/Comments/{commentId}";
        url = url.replace("{accountId}", accountId);
        url = url.replace("{commentId}", commentId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.AccountComment, request, context);
        });
        return request;
    }

    public AddComment(body: any, contexts: DataContext[] | DataContext, accountId: string): Observable<ITypedServiceResponse<Models.AccountComment>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountId}/Comments";
        url = url.replace("{accountId}", accountId);

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.AccountComment, request, context);
        });
        return request;
    }

    public UpdateComment(body: any, contexts: DataContext[] | DataContext, accountId: string, idOrCorrelationKey: string): Observable<ITypedServiceResponse<Models.AccountComment>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountId}/Comments/{idOrCorrelationKey}";
        url = url.replace("{accountId}", accountId);
        url = url.replace("{idOrCorrelationKey}", idOrCorrelationKey);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.AccountComment, request, context);
        });
        return request;
    }

    public DeleteComment(idOfDeletedItem: string, contexts: DataContext[] | DataContext, accountId: string, commentId: string): Observable<ITypedServiceResponse<Models.AccountComment>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountId}/Comments/{commentId}";
        url = url.replace("{accountId}", accountId);
        url = url.replace("{commentId}", commentId);

        // Make the request
        let request = this.http.delete<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ResurrectAComment(body: any, contexts: DataContext[] | DataContext, accountId: string, commentId: string): Observable<ITypedServiceResponse<Models.AccountComment>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountId}/Comments/{commentId}/Resurrect";
        url = url.replace("{accountId}", accountId);
        url = url.replace("{commentId}", commentId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.AccountComment, request, context);
        });
        return request;
    }

    public ListDocumentCategoriesForAccount(accountIdOrCode: string): Observable<Response> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}/DocumentCategories";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ReverseInvoice(idOfDeletedItem: string, contexts: DataContext[] | DataContext, accountIdOrCode: string, invoiceId: string): Observable<ITypedServiceResponse<Models.Activity>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}/Invoices/{invoiceId}";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{invoiceId}", invoiceId);

        // Make the request
        let request = this.http.delete<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public AddInvoice(body: any, contexts: DataContext[] | DataContext, accountIdOrCode: string, invoiceId: string): Observable<ITypedServiceResponse<Models.Activity>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}/Invoices/{invoiceId}";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{invoiceId}", invoiceId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Activity, request, context);
        });
        return request;
    }

    public ListPolicies(contexts: DataContext[] | DataContext,
        optionalParameters: {
            accountId?: string,
            producerId?: string,
            programId?: string,
            fromEff?: string,
            fromExp?: string,
            toEff?: string,
            toExp?: string,
            updatedSince?: string,
            workflowSetId?: string,
            policySetId?: string,
            filterRenewed?: string
        }): Observable<ITypedServiceResponse<Models.Policy>> {
        // Build the url
        var url = AccountsService.baseUrl + "Policies";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.accountId != null)
            params = params.set('accountId', optionalParameters.accountId);
        if (optionalParameters?.producerId != null)
            params = params.set('producerId', optionalParameters.producerId);
        if (optionalParameters?.programId != null)
            params = params.set('programId', optionalParameters.programId);
        if (optionalParameters?.fromEff != null)
            params = params.set('fromEff', optionalParameters.fromEff);
        if (optionalParameters?.fromExp != null)
            params = params.set('fromExp', optionalParameters.fromExp);
        if (optionalParameters?.toEff != null)
            params = params.set('toEff', optionalParameters.toEff);
        if (optionalParameters?.toExp != null)
            params = params.set('toExp', optionalParameters.toExp);
        if (optionalParameters?.updatedSince != null)
            params = params.set('updatedSince', optionalParameters.updatedSince);
        if (optionalParameters?.workflowSetId != null)
            params = params.set('workflowSetId', optionalParameters.workflowSetId);
        if (optionalParameters?.policySetId != null)
            params = params.set('policySetId', optionalParameters.policySetId);
        if (optionalParameters?.filterRenewed != null)
            params = params.set('filterRenewed', optionalParameters.filterRenewed);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Policy, request, context);
        });
        return request;
    }

    public RetrieveAPolicy(contexts: DataContext[] | DataContext, accountIdOrCode: string, policyId: string): Observable<ITypedServiceResponse<Models.Policy>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}/Policies/{policyId}";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{policyId}", policyId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Policy, request, context);
        });
        return request;
    }

    public UpdateCommission(body: any, contexts: DataContext[] | DataContext, accountIdOrCode: string, policyId: string): Observable<ITypedServiceResponse<Models.Policy>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}/Policies/{policyId}/UpdateCommission";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{policyId}", policyId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Policy, request, context);
        });
        return request;
    }

    public RevertPolicyCommission(body: any, contexts: DataContext[] | DataContext, accountIdOrCode: string, policyId: string, edition: string): Observable<ITypedServiceResponse<Models.Policy>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}/Policies/{policyId}/ResetCommission";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{policyId}", policyId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('edition', edition);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Policy, request, context);
        });
        return request;
    }

    public ImportPolicy(body: any, contexts: DataContext[] | DataContext, accountIdOrCode: string, policySetId: string, policyStatus: string, policyNumber: string): Observable<ITypedServiceResponse<Models.Policy>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}/PolicySet/{policySetId}/Policy";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{policySetId}", policySetId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('policyStatus', policyStatus);
        params = params.set('policyNumber', policyNumber);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Policy, request, context);
        });
        return request;
    }

    public ImportPolicyAndCreateOrModifyPolicySet(body: any, contexts: DataContext[] | DataContext, accountIdOrCode: string, policyStatus: string, policyNumber: string): Observable<ITypedServiceResponse<Models.Policy>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}/PolicySet/Policy";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('policyStatus', policyStatus);
        params = params.set('policyNumber', policyNumber);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Policy, request, context);
        });
        return request;
    }

    public ListPolicysets(contexts: DataContext[] | DataContext, accountIdOrCode: string,
        optionalParameters: {
            includeDeleted?: string,
            workflowSetId?: string
        }): Observable<ITypedServiceResponse<Models.PolicySet>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}/PolicySets";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.includeDeleted != null)
            params = params.set('includeDeleted', optionalParameters.includeDeleted);
        if (optionalParameters?.workflowSetId != null)
            params = params.set('workflowSetId', optionalParameters.workflowSetId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.PolicySet, request, context);
        });
        return request;
    }

    public RetrieveAPolicyset(contexts: DataContext[] | DataContext, accountIdOrCode: string, policySetIdOrWorkflowSetId: string): Observable<ITypedServiceResponse<Models.PolicySet>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}/PolicySets/{policySetIdOrWorkflowSetId}";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{policySetIdOrWorkflowSetId}", policySetIdOrWorkflowSetId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.PolicySet, request, context);
        });
        return request;
    }

    public UpdatePolicysetTerm(body: any, contexts: DataContext[] | DataContext, accountIdOrCode: string, policySetId: string): Observable<ITypedServiceResponse<Models.PolicySet>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}/PolicySets/{policySetId}/Term";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{policySetId}", policySetId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.PolicySet, request, context);
        });
        return request;
    }

    public ToggleProduct(body: any, contexts: DataContext[] | DataContext, accountIdOrCode: string, policySetId: string, workflowId: string): Observable<ITypedServiceResponse<Models.Transaction>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}/PolicySets/{policySetId}/ProductSelection";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{policySetId}", policySetId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('workflowId', workflowId);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Transaction, request, context);
        });
        return request;
    }

    public ImportPolicyset(body: any, contexts: DataContext[] | DataContext, accountIdOrCode: string, programId: string): Observable<ITypedServiceResponse<Models.PolicySet>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}/PolicySet";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('programId', programId);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.PolicySet, request, context);
        });
        return request;
    }

    public DeletePolicyset(idOfDeletedItem: string, contexts: DataContext[] | DataContext, accountIdOrCode: string, policySetId: string): Observable<ITypedServiceResponse<Models.PolicySet>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}/PolicySets/{policySetId}";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{policySetId}", policySetId);

        // Make the request
        let request = this.http.delete<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ListTransactions(contexts: DataContext[] | DataContext,
        optionalParameters: {
            accountId?: string,
            workflowId?: string,
            producerId?: string,
            programId?: string,
            type?: string,
            fromEff?: string,
            toEff?: string,
            updatedSince?: string,
            workflowSetId?: string,
            policyId?: string,
            includeDeselected?: string
        }): Observable<ITypedServiceResponse<Models.Transaction>> {
        // Build the url
        var url = AccountsService.baseUrl + "Transactions";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.accountId != null)
            params = params.set('accountId', optionalParameters.accountId);
        if (optionalParameters?.workflowId != null)
            params = params.set('workflowId', optionalParameters.workflowId);
        if (optionalParameters?.producerId != null)
            params = params.set('producerId', optionalParameters.producerId);
        if (optionalParameters?.programId != null)
            params = params.set('programId', optionalParameters.programId);
        if (optionalParameters?.type != null)
            params = params.set('type', optionalParameters.type);
        if (optionalParameters?.fromEff != null)
            params = params.set('fromEff', optionalParameters.fromEff);
        if (optionalParameters?.toEff != null)
            params = params.set('toEff', optionalParameters.toEff);
        if (optionalParameters?.updatedSince != null)
            params = params.set('updatedSince', optionalParameters.updatedSince);
        if (optionalParameters?.workflowSetId != null)
            params = params.set('workflowSetId', optionalParameters.workflowSetId);
        if (optionalParameters?.policyId != null)
            params = params.set('policyId', optionalParameters.policyId);
        if (optionalParameters?.includeDeselected != null)
            params = params.set('includeDeselected', optionalParameters.includeDeselected);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Transaction, request, context);
        });
        return request;
    }

    public RetrieveATransaction(contexts: DataContext[] | DataContext, accountIdOrCode: string, transactionId: string): Observable<ITypedServiceResponse<Models.Transaction>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}/Transactions/{transactionId}";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{transactionId}", transactionId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Transaction, request, context);
        });
        return request;
    }

    public SetRatingResultsForATransaction(body: any, contexts: DataContext[] | DataContext, accountIdOrCode: string): Observable<ITypedServiceResponse<Models.TransactionDetails>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}/RatingResults";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.TransactionDetails, request, context);
        });
        return request;
    }

    public ImportTransaction(body: any, contexts: DataContext[] | DataContext, accountIdOrCode: string, policyId: string): Observable<ITypedServiceResponse<Models.Transaction>> {
        // Build the url
        var url = AccountsService.baseUrl + "{accountIdOrCode}/Policies/{policyId}/Transaction";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);
        url = url.replace("{policyId}", policyId);

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Transaction, request, context);
        });
        return request;
    }

    public RetrieveListOfRecentUserAccountIds(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.UserRecentAccounts>> {
        // Build the url
        var url = AccountsService.baseUrl + "User/Recents";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.UserRecentAccounts, request, context);
        });
        return request;
    }

    public AddAccountToRecents(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.UserRecentAccounts>> {
        // Build the url
        var url = AccountsService.baseUrl + "User/Recents";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.UserRecentAccounts, request, context);
        });
        return request;
    }

    public AddAccountToFavorites(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.SMIdentity>> {
        // Build the url
        var url = AccountsService.baseUrl + "User/Favorites";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.SMIdentity, request, context);
        });
        return request;
    }

    public RemoveAccountFromFavorites(idOfDeletedItem: string, contexts: DataContext[] | DataContext, accountIdOrCode: string): Observable<ITypedServiceResponse<Models.SMIdentity>> {
        // Build the url
        var url = AccountsService.baseUrl + "User/Favorites/{accountIdOrCode}";
        url = url.replace("{accountIdOrCode}", accountIdOrCode);

        // Make the request
        let request = this.http.delete<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ListUserNotificationCenters(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.UserNotificationCenter>> {
        // Build the url
        var url = AccountsService.baseUrl + "Notifications";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.UserNotificationCenter, request, context);
        });
        return request;
    }

    public RemoveNotificationsForUser(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.UserNotificationCenter>> {
        // Build the url
        var url = AccountsService.baseUrl + "Notifications/Remove";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.UserNotificationCenter, request, context);
        });
        return request;
    }

    public MarkNotificationsAsRead(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.UserNotificationCenter>> {
        // Build the url
        var url = AccountsService.baseUrl + "Notifications/Read";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.UserNotificationCenter, request, context);
        });
        return request;
    }

}