export enum DocumentExtractionStatus {
    EXTRACTING = 'Extracting',
    REVIEW = 'Review',
    IMPORTING = 'Importing',
    COMPLETE = 'Complete',
    STOPPING = 'Stopping',
    STOPPED = 'Stopped',
    FAILED = 'Failed',
}

export enum CompanyType {
    PRODUCER = 'Producer',
    INSURED = 'Insured',
}

export enum EmailType {
    PERSONAL = 'Personal',
    WORK = 'Work',
}

export enum PhoneNumberType {
    HOME = 'Home',
    MOBILE = 'Mobile',
    WORK = 'Work',
}

export enum AccountStatus {
    TEMP = 'Temp',
    OPEN = 'Open',
    CLOSED = 'Closed',
    FROZEN = 'Frozen',
}

export enum AddressPart {
    CAREOF = 'c/o',
    NUMBER = 'Honorific',
    STREET = 'Street',
    UNIT = 'Unit',
    CITY = 'City',
    COUNTY = 'County',
    STATE = 'State',
    ZIP = 'Zip',
    ZIPPLUS = 'ZipPlus4',
}

export enum AreaType {
    DEV = 'Dev',
    TEST = 'Test',
    QA = 'QA',
    STAGING = 'Staging',
    PRODUCTION = 'Production',
}

export enum BusinessTimeUnits {
    CALENDAR_HOURS = 'calendar hours',
    BUSINESS_HOURS = 'business hours',
    CALENDAR_DAYS = 'calendar days',
    BUSINESS_DAYS = 'business days',
    WEEKS = 'weeks',
    MONTHS = 'months',
}

export enum DashboardExternalSourceType {
    InsurityBI = 'Insurity BI',
}

export enum DashboardWidgetType {
    KPI = 'KPI',
    Gauge = 'Gauge',
    UserInfo = 'User Info',
    External = 'External',
}

export enum DataType {
    STRING = 'string',
    INTEGER = 'integer',
    DECIMAL = 'decimal',
    MONEY = 'money',
    BOOLEAN = 'boolean',
    DATE = 'date',
    TIMESTAMP = 'timestamp',
    LIST_OF_STRINGS = 'list_of_strings',
    EMAIL = 'email',
    URL = 'url',
    PHONE = 'phone',
    ZIP = 'zip',
}

export enum DeployableDeploymentStatus {
    VALIDATING = 'Validating',
    PUBLISHING = 'Publishing',
    DEPLOYING = 'Deploying',
    DEPLOYED = 'Deployed',
    ERRORED = 'Errored',
}

export enum DocumentSourceType {
    RatingService = 'RatingService',
    DocumentGeneration = 'DocumentGeneration',
    DocumentUpload = 'DocumentUpload',
}

export enum EventActionType {
    FIRE_WEBHOOK = 'FireWebhook',
    CHANGE_WORKFLOW_SET_STATUS = 'ChangeWorkflowSetStatus',
    START_WORKFLOW = 'StartWorkflow',
    TRIGGER_RULE = 'TriggerRule',
    SEND_ACCOUNT_INFO = 'SendAccountInfo',
    POLICY_OPERATION = 'PolicyOperation',
}

export enum ExecutionStatuses {
    NOT_READY = 'NotReady',
    READY = 'Ready',
    EXECUTING = 'Executing',
}

export enum ValidationCode {
    I1001 = 'I1001',
    I1002 = 'I1002',
    I1005 = 'I1005',
    I1010 = 'I1010',
    I1011 = 'I1011',
    I1012 = 'I1012',
    I1014 = 'I1014',
    I1015 = 'I1015',
    I1016 = 'I1016',
    I1017 = 'I1017',
    I1018 = 'I1018',
    I1024 = 'I1024',
    I1030 = 'I1030',
    I1031 = 'I1031',
    I1032 = 'I1032',
    I1033 = 'I1033',
    I9999 = 'I9999',
    E1001 = 'E1001',
    E1002 = 'E1002',
    E1003 = 'E1003',
    E1004 = 'E1004',
    E1005 = 'E1005',
    E1006 = 'E1006',
    E1007 = 'E1007',
    C1001 = 'C1001',
    C1002 = 'C1002',
    C1003 = 'C1003',
    C1004 = 'C1004',
    C1005 = 'C1005',
    C1006 = 'C1006',
    C1008 = 'C1008',
    C1010 = 'C1010',
    C1017 = 'C1017',
    B1001 = 'B1001',
    B1002 = 'B1002',
    B1003 = 'B1003',
    B1004 = 'B1004',
    B1006 = 'B1006',
    B1008 = 'B1008',
    B1010 = 'B1010',
    B1011 = 'B1011',
    B1012 = 'B1012',
    B1013 = 'B1013',
    B1014 = 'B1014',
    B1015 = 'B1015',
    B1016 = 'B1016',
    B1017 = 'B1017',
    B1018 = 'B1018',
    B1019 = 'B1019',
    B1020 = 'B1020',
    B1021 = 'B1021',
    B1022 = 'B1022',
    B1023 = 'B1023',
    B1024 = 'B1024',
    B1025 = 'B1025',
    B1026 = 'B1026',
    B1027 = 'B1027',
    B1028 = 'B1028',
    B1029 = 'B1029',
    B1030 = 'B1030',
    B1031 = 'B1031',
    B1032 = 'B1032',
}

export enum InjectionSourceType {
    Application = 'Application',
    Service = 'Service',
    ObjectSync = 'ObjectSync',
    ImportOrCopy = 'Import/Copy',
    Calculation = 'Calculation',
    Wireup = 'Wireup',
}

export enum NamePart {
    HONORIFIC = 'Honorific',
    FIRST = 'First',
    MIDDLE = 'Middle',
    LAST = 'Last',
    SUFFIX = 'Suffix',
    FULL = 'Full',
}

export enum NotificationType {
    JOURNAL_ENTRY = 'JournalEntry',
    COMMENT = 'Comment',
    TASK_DELEGATION = 'TaskDelegation',
    PROGRAM_REVISION = 'ProgramRevision',
    EMAIL = 'Email',
}

export enum PolicyOperation {
    START_TRANSACTION = 'StartTransaction',
    REVERT_ALL = 'RevertAll',
    REVERT_UNUSED = 'RevertUnused',
    SEND_POLICY_DATA = 'SendPolicyData',
    RETRIEVE_POLICY_DATA = 'RetrievePolicyData',
    CUSTOM = 'Custom',
}

export enum PolicyOperationAction {
    RATE = 'Rate',
    START_TRANSACTION = 'StartTransaction',
    REVERT_TRANSACTION = 'RevertTransaction',
    SEND_POLICY_DATA = 'SendPolicyData',
    RETRIEVE_POLICY_DATA = 'RetrievePolicyData',
    CUSTOM_POLICY_OPERATION = 'CustomPolicyOperation',
}

export enum PolicyRatedStatus {
    RATE = 'Rate',
    QUOTE = 'Quote',
    BIND = 'Bind',
    ISSUE = 'Issue',
}

export enum PolicyState {
    IN_FORCE = 'InForce',
    PREVIOUSLY_CANCELLED = 'PreviouslyCancelled',
}

export enum PolicyStatus {
    EXCLUDED = 'Excluded',
    SELECTED = 'Selected',
    QUOTED = 'Quoted',
    IN_FORCE = 'In Force',
    CANCELLED = 'Cancelled',
    EXPIRED = 'Expired',
    CLOSED = 'Closed',
}

export enum PolicyUpdateType {
    Essential = 'Essential',
    Coverage = 'Coverage',
    Forms = 'Forms',
    Custom = 'Custom',
    EssentialWC = 'EssentialWC',
    CoverageWC = 'CoverageWC',
    EssentialAuto = 'EssentialAuto',
    CoverageAuto = 'CoverageAuto',
}

export enum PrimaryPolicyAction {
    ADD = 'Add',
    MODIFY = 'Modify',
    REMOVE = 'Remove',
}

export enum PrioritizationType {
    LATE_DEADLINE = 'LateDeadline',
    IMMINENT_DEADLINE = 'ImminentDeadline',
    PENDING_DEADLINE = 'PendingDeadline',
    LATE_SERVICE_LEVEL = 'LateServiceLevel',
    IMMINENT_SERVICE_LEVEL = 'ImminentServiceLevel',
    PENDING_SERVICE_LEVEL = 'PendingServiceLevel',
    LATE_DUE_DATE = 'LateDueDate',
    IMMINENT_DUE_DATE = 'ImminentDueDate',
    PENDING_DUE_DATE = 'PendingDueDate',
}

export enum ProductEligibilityStatus {
    INELIGIBLE = 'Ineligible',
    ELIGIBLE = 'Eligible',
    PRESELECTED = 'Preselected',
    REQUIRED = 'Required',
}

export enum RatingCommitOptions {
    NOT_ALLOWED = 'Not Allowed',
    ALLOWED = 'Allowed',
    REQUIRED = 'Required',
}

export enum RatingDocumentGenerationOptions {
    NONE = 'None',
    FULL = 'Full',
    ENDORSEMENT = 'Endorsement',
    ADDED = 'Added',
}

export enum SettingsKeyNames {
    ENDPOINT = 'Endpoint',
    VIEW_URL = 'ViewURL',
    EDIT_URL = 'EditURL',
}

export enum TaskApprovalRejectionType {
    ALL = 'All',
    ONLY_THIS = 'OnlyThis',
}

export enum TaskRatingResults {
    NOT_RATED = 'NotRated',
    ERRORED = 'Errored',
    SUCCESS = 'Success',
    UPDATED = 'Updated',
    INVALID = 'Invalid',
}

export enum TransactionRatingStatus {
    NOT_RATED = 'NotRated',
    SETUP = 'Setup',
    RATING = 'Rating',
    RATED = 'Rated',
    ISSUED = 'Issued',
    ERRORED = 'Errored',
    REVERTED = 'Reverted',
}

export enum TransactionSelectionStatus {
    SELECTED = 'Selected',
    DESELECTED = 'Deselected',
    AUTOMATIC = 'Automatic',
}

export enum ValidationLevels {
    TIP = 'Tip',
    WARNING = 'Warning',
    SOFT = 'Soft',
    HARD = 'Hard',
}

export enum WebhookActions {
    GENERAL = 'General',
    SEND_ACCOUNT_INFO = 'SendAccountInfo',
    POLICY_OPERATION = 'PolicyOperation',
    RATING = 'Rating',
}

export enum WebhookEventType {
    TASK_EXECUTED = 'TaskExecuted',
    TASK_COMPLETED = 'TaskCompleted',
    TASK_COMPLETING = 'TaskCompleting',
    TASK_UNCOMPLETED = 'TaskUncompleted',
    PHASE_COMPLETED = 'PhaseCompleted',
    PHASE_COMPLETING = 'PhaseCompleting',
    WORKFLOW_STARTED = 'WorkflowStarted',
    WORKFLOW_COMPLETED = 'WorkflowCompleted',
    WORKFLOW_COMPLETING = 'WorkflowCompleting',
    WORKFLOW_STOPPED = 'WorkflowStopped',
    WORKFLOW_STOPPING = 'WorkflowStopping',
    WORKFLOW_RESUMED = 'WorkflowResumed',
    QUEUE_WORKFLOW_PROVISIONING = 'QueueWorkflowProvisioning',
    COPY_TO_QUOTE = 'CopyToQuote',
}

export enum WebhookMode {
    FIRE_AND_FORGET = 'FireAndForget',
    VERIFY_COMPLETION = 'VerifyCompletion',
}

export enum WorkflowStatus {
    RUNNING = 'Running',
    STOPPING = 'Stopping',
    STOPPED = 'Stopped',
    COMPLETING = 'Completing',
    COMPLETED = 'Completed',
    CLOSED = 'Closed',
}

export enum AdminDBVersion {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
}

export enum AreaDBVersion {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
}

export enum ConfigurationDBVersion {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    _6 = '6',
    _7 = '7',
    _8 = '8',
    _9 = '9',
    _10 = '10',
    _11 = '11',
    _12 = '12',
    _13 = '13',
    _14 = '14',
    _15 = '15',
    _16 = '16',
}

export enum Currency {
    USD = 'USD',
    CAD = 'CAD',
    EUR = 'EUR',
    GBP = 'GBP',
}

export enum RatingMessageType {
    INFO = 'Info',
    WARNING = 'Warning',
    ERROR = 'Error',
}

