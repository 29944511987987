@if (state == 'badge') {
    <button id="TimerButton"
            class="badge on_{{background}} {{timerState}}"
            (click)="timerClicked.emit()">
        <svg class="unity-icon-stopwatch">
            <use xlink:href="#unity-icon-stopwatch"></use>
        </svg>
        @if (includeTimeInfo) {
        <span class="timer">
            <span id="TaskTime">
                <sm-time-since [start]="dueOn" [displayTimeUntil]="timerState != 'late'"/>
            </span>
        </span>
        }
    </button>
} @else if (state == 'bar') {
    <button id="TimerButton"
            class="bar on_{{background}} {{timerState}}"
            (click)="timerClicked.emit()">
        <span class="timer_badge--task_name single_line_ellipsis">
            <h2 id="TaskName" class="sle">{{ taskName }}</h2>
        </span>
        <button class="inner {{timerState}}" [ngClass]="{ 'badge': timerState != 'late', 'standalone standalone_on_red': timerState == 'late'}">
            <svg class="unity-icon-stopwatch">
                <use xlink:href="#unity-icon-stopwatch"></use>
            </svg>
            @if (includeTimeInfo) {
            <span class="timer">
                <span id="TaskTime">
                    <sm-time-since [start]="dueOn" [displayTimeUntil]="timerState != 'late'"/>
                </span>
            </span>
            }
        </button>     
    </button>
} @else {
    <button id="TimerButton"
            class="standalone on_{{background}} {{timerState}}"
            (click)="timerClicked.emit()">
        @if (timerState != 'late') {
        <svg class="unity-icon-stopwatch">
            <use xlink:href="#unity-icon-stopwatch"/>
        </svg>
        }
        @if (timerState == 'late') {
        <svg class="unity-icon-stopwatch-fill">
            <use xlink:href="#unity-icon-stopwatch-fill" />
        </svg>
        }
        @if (includeTimeInfo) {
        <sm-time-since id="TaskTime" [start]="dueOn" [displayTimeUntil]="timerState != 'late'"/>
        }
    </button>
}