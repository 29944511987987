import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
    name: 'smDate'
})
export class SmDatePipe implements PipeTransform {
    // Short:        1/7/2023
    // ShortGrid:    01/07/2023
    // Medium:       Jan 7, 2023
    // Long:         January 7, 2023
    // Full:         Saturday, January 7, 2023
    // Timestamp
    transform(date: any, format: SmDateFormats) : string {

        if (_.isNil(date) || date == "")
            return null;

        if (_.isString(date) || _.isNumber(date))
            date = new Date(date);

        switch (format) {
            case SmDateFormats.Short:
            case SmDateFormats[SmDateFormats.Short]:
                return new Intl.DateTimeFormat(navigator.language, {
                    month: "numeric",
                    day: "numeric",
                    year: "numeric",
                }).format(date);

            case SmDateFormats.ShortGrid:
            case SmDateFormats[SmDateFormats.ShortGrid]:
                return new Intl.DateTimeFormat(navigator.language, {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                }).format(date);
            
            case SmDateFormats.Medium:
            case SmDateFormats[SmDateFormats.Medium]:
                return new Intl.DateTimeFormat(navigator.language, {dateStyle: "medium"}).format(date);

            case SmDateFormats.Long:
            case SmDateFormats[SmDateFormats.Long]:
                return new Intl.DateTimeFormat(navigator.language, {dateStyle: "long"}).format(date);

            case SmDateFormats.Full: 
            case SmDateFormats[SmDateFormats.Full]:
                return new Intl.DateTimeFormat(navigator.language, {dateStyle: "full"}).format(date);

            case SmDateFormats.Timestamp:
            case SmDateFormats[SmDateFormats.Timestamp]:
                return new Intl.DateTimeFormat(navigator.language, { dateStyle: "medium", timeStyle: "medium" }).format(date);

            case SmDateFormats.ShortTime:
            case SmDateFormats[SmDateFormats.ShortTime]:
                return new Intl.DateTimeFormat(navigator.language, { timeStyle: "short", hour12: true }).format(date);

            case SmDateFormats.ShortDayOfWeek:
            case SmDateFormats[SmDateFormats.ShortDayOfWeek]:
                return new Intl.DateTimeFormat(navigator.language, { weekday: 'short' }).format(date);
            
            case SmDateFormats.DayOfWeek:
            case SmDateFormats[SmDateFormats.DayOfWeek]:
                return new Intl.DateTimeFormat(navigator.language, { weekday: 'long' }).format(date);
        }
    }
}

export enum SmDateFormats {
    Short = "Short",
    ShortGrid = "ShortGrid",
    Medium = "Medium",
    Long = "Long",
    Full = "Full",
    Timestamp = "Timestamp",
    ShortTime = "ShortTime",
    ShortDayOfWeek = "ShortDayOfWeek",
    DayOfWeek = "DayOfWeek"
}