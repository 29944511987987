<unity-header [view]="'Admin'">
	<div class="d-flex align-items-center" right>
		<span class="text-light pe-3">ENV : {{environmentName}}</span>
		<span class="text-light pe-3">REFRESH RATE</span>
		<span class="text-light pe-3">{{refreshRate > 300 ? 'OFF' : (refreshRate >= 60 ? Math.floor(refreshRate / 60) + 'm ' : '') + refreshRate % 60 + 's' }}</span>
		<kendo-slider [tickPlacement]="'none'"
				[showButtons]="false"
				[min]="minRefreshRateSeconds"
				[max]="maxRefreshRateSeconds + refreshRateSliderIncrement"
				[smallStep]="refreshRateSliderIncrement"
				(ngModelChange)="updateRefreshRate($event)"
				[(ngModel)]="refreshRate"></kendo-slider>
	</div>
</unity-header>

<main>
	<router-outlet></router-outlet>
</main>