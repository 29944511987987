export const environment = {
    production: true,
    "env": "dev",
    "baseUrl": "https://smdev.instecservices.com",
    "applicationEndpoint": "https://smdev-api.instecservices.com",
    "syncHost": "https://smdev-sync.instecservices.com",
    "documentationUrl": "https://smdev-api-portal.instecservices.com",
    "access_token":"",
    "applicationInsights": "InstrumentationKey=d1872550-8b71-4d5c-901e-b5dd5acb2e72;IngestionEndpoint=https://eastus-4.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/",
    "auth": {
        "domain": "instecdev.auth0.com",
        "clientId": "SGwzdGblI0x1r9MRo39rq1wQA6cjt9ho",
    },
    "qlik": {
        "authUrl": "https://insuritybi.dev.insurity.com/sure-uw/hub/",
        "reportsUrl": "https://insuritybi.dev.insurity.com/sure-uw/sense/app/e2208206-d9e3-4866-80d7-08d99b623e0f/sheet/681cc4bf-2fea-402e-af05-3e49d7b19ca6/"
    },
    "ikcDocumentationUrl": "https://docs.insurity.com/category/sureunderwriting",
    "ikcIntegrationDocumentationUrl": "https://docs.insurity.com/bundle/SureUnderwritingUserHelp/page/webhooks.html",
    "requireAPIM": true
};