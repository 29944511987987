import { TimerType } from '@Core/Lib/Enums/timer-type-enum';
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges } from '@angular/core';

@Component({
    selector: 'task-timer',
    templateUrl: './task-timer.component.html',
    styleUrls: ['./task-timer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskTimerComponent {
    @Input() dueOn: Date;
    @Input() background: 'dark' | 'light';
    @Input() timerType: TimerType;
    @Input() taskName: string = null;
    @Input() state: 'bar' | 'badge' | 'standalone' = 'standalone';
    @Input() includeTimeInfo: boolean = true;

    @Output() timerClicked: EventEmitter<string> = new EventEmitter();

    public timerStyle: string;

    public timerState: 'pending' | 'imminent' | 'late';

    constructor(private cdr: ChangeDetectorRef) { }

    public ngOnInit(): void {
        this.isDueSoonOrPastDueSetup();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.dueOn?.currentValue != changes.dueOn?.previousValue) {
            this.isDueSoonOrPastDueSetup();
        }
    }

    public resetTimer() {
        // Set the time information the first time
        this.isDueSoonOrPastDueSetup();
    }

    isDueSoonOrPastDueSetup() {       
        if (!this.dueOn)
            return;

        const remainingTime = new Date(this.dueOn).getTime() - new Date().getTime();
        const oneDay = 1000 * 60 * 60 * 24;
        const deadlineImminent = oneDay * 5; // 5 days 
        const serviceLevelImminent = oneDay * 2; // 1 day

        if (remainingTime < 0) {
            this.timerState = 'late';
        } else {
            if ((this.timerType == TimerType.Deadline && remainingTime < deadlineImminent)
                || (this.timerType == TimerType.SLA && remainingTime < serviceLevelImminent)
                || (!this.timerType && remainingTime < serviceLevelImminent)) {
                this.timerState = 'imminent';
            } else {
                this.timerState = 'pending';
            }
        } 

        this.cdr.detectChanges();
    }
}