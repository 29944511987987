<ng-template #templateContent>
    <ng-content></ng-content>
</ng-template>
<button unity-button
        class="icon-only help_tooltip"
        [ngClass]="{ 'on_dark' : color == 'white'}"
        [ngbPopover]="templateContent"
        [popoverTitle]="title"
        [triggers]="trigger"
        [placement]="placement"
        [autoClose]="'outside'"
        container="body"
        tabindex="0"
        [popoverClass]="'unity-tooltip bg-' + (bgColor || color)">
    <svg attr.class="unity-icon unity-icon-{{icon}}">
        <use attr.xlink:href="#unity-icon-{{icon}}"></use>
    </svg>
</button>
